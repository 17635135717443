import Avatar from '@component/avatar/Avatar';
import FlexBox from '@component/FlexBox';
import LazyImage from '@component/LazyImage';
import { useAppContext } from '@context/app/AppContext';
import { CartItem } from '@reducer/cartReducer';
import { getSession } from 'next-auth/react';
import Link from 'next/link';
import CartApi from 'pages/api/cart/cart';
import React, { Fragment, useCallback, useEffect } from 'react';
import Button from '../buttons/Button';
import Divider from '../Divider';
import Icon from '../icon/Icon';
import Image from '@component/Image';
import Typography, { H5, Paragraph, Tiny } from '../Typography';
import { StyledMiniCart } from './MiniCartStyle';
import { useSession } from 'next-auth/react';

type MiniCartProps = {
  toggleSidenav?: () => void;
};

const MiniCart: React.FC<MiniCartProps> = ({ toggleSidenav }) => {
  const { state, dispatch } = useAppContext();
  const { cartList } = state.cart;
  const { data: session, status } = useSession();
 const loading = status === 'loading';;
  const token = session?.accessToken.toString();

  useEffect(() => {
    const getUserCart = async () => {
      try {
        const res = await CartApi.getCart(token);
        const { data } = res;

        console.log('DATA MINI CAR', data);
        data.forEach((item) => {
          const { quantity, title, actualPrice, price, imageLink, stock, id } = item;
          dispatch({
            type: 'CHANGE_CART_AMOUNT',
            payload: {
              id,
              stock,
              name: title,
              qty: quantity,
              price: actualPrice,
              imgUrl: { fileUrl: imageLink },
            },
          });
        });
      } catch (err) {
        console.log('ERROR getting user cart', err);
      }
    };
    getUserCart();
  }, []);

  const handleCartAmountChange = useCallback(
    (amount, product) => () => {
      dispatch({
        type: 'CHANGE_CART_AMOUNT',
        payload: {
          ...product,
          qty: amount,
        },
      });
    },
    []
  );

  const getTotalPrice = () => {
    return cartList.reduce((accumulator, item) => accumulator + item.price * item.qty, 0) || 0;
  };

  const addItemsToCart = async () => {
    cartList.forEach(async (item) => {
      try {
        const response = await CartApi.addToCart(token, item.id, item.qty);
        console.log('RESPONSE --->', response);
      } catch (error) {
        console.log('error in adding item to cart');
      }
    });
  };

  return (
    <StyledMiniCart>
      <div className="cart-list">
        <FlexBox alignItems="center" m="0px 20px" height="74px">
          <Icon size="1.75rem">bag</Icon>
          <Typography fontWeight={600} fontSize="16px" ml="0.5rem">
            {cartList.length} item
          </Typography>
        </FlexBox>

        <Divider />

        {!!!cartList.length && (
          <FlexBox
            flexDirection="column"
            alignItems="center"
            justifyContent="center"
            height="calc(100% - 80px)"
          >
            <Image src="/assets/images/logos/shopping-bag.svg" width="90px" height="100%" />
            <Paragraph mt="1rem" color="text.muted" textAlign="center" maxWidth="200px">
              Your shopping bag is empty. Start shopping
            </Paragraph>
          </FlexBox>
        )}
        {cartList.map((item: CartItem) => (
          <Fragment key={item.id}>
            <div className="cart-item">
              <FlexBox alignItems="center" flexDirection="column">
                <Button
                  variant="outlined"
                  color="primary"
                  padding="5px"
                  size="none"
                  borderColor="primary.light"
                  borderRadius="300px"
                  onClick={handleCartAmountChange(item.qty + 1, item)}
                  disabled={item.stock <= item.qty}
                >
                  <Icon variant="small">plus</Icon>
                </Button>
                <Typography fontWeight={600} fontSize="15px" my="3px">
                  {item.qty}
                </Typography>
                <Button
                  variant="outlined"
                  color="primary"
                  padding="5px"
                  size="none"
                  borderColor="primary.light"
                  borderRadius="300px"
                  onClick={handleCartAmountChange(item.qty - 1, item)}
                  disabled={item.qty === 1}
                >
                  <Icon variant="small">minus</Icon>
                </Button>
              </FlexBox>

              <Link href={`/product/${item.id}`}>
                <a>
                  <Avatar
                    src={item?.imgUrl?.fileUrl || '/assets/images/products/iphone-x.png'}
                    mx="1rem"
                    alt={item.name}
                    size={76}
                  />
                </a>
              </Link>

              <div className="product-details">
                <Link href={`/product/${item.id}`}>
                  <a>
                    <H5 className="title" fontSize="14px">
                      {item.name}
                    </H5>
                  </a>
                </Link>
                <Tiny color="text.muted">
                  RM{(item.price * 1).toFixed(2)} x {item.qty}
                </Tiny>
                <Typography fontWeight={600} fontSize="14px" color="primary.main" mt="4px">
                  RM{(item.qty * item.price).toFixed(2)}
                </Typography>
              </div>

              <Icon
                className="clear-icon"
                size="1rem"
                ml="1.25rem"
                onClick={handleCartAmountChange(0, item)}
              >
                close
              </Icon>
            </div>
            <Divider />
          </Fragment>
        ))}
      </div>

      {!!cartList.length && (
        <Fragment>
          <Link href="/checkout">
            <Button
              variant="contained"
              color="primary"
              m="1rem 1rem 0.75rem"
              onClick={() => {
                toggleSidenav();
                addItemsToCart();
              }}
            >
              <Typography fontWeight={600}>
                Checkout Now ~~ (MYR{getTotalPrice().toFixed(2)})
              </Typography>
            </Button>
          </Link>
          <Link href="/cart">
            <Button
              color="primary"
              variant="outlined"
              m="0px 1rem 0.75rem"
              onClick={() => {
                // toggleSidenav();
                addItemsToCart();
              }}
            >
              <Typography fontWeight={600}>View Cart</Typography>
            </Button>
          </Link>
        </Fragment>
      )}
    </StyledMiniCart>
  );
};

MiniCart.defaultProps = {
  toggleSidenav: () => {},
};

export default MiniCart;
