import React from 'react';
import { BorderProps, ColorProps } from 'styled-system';
import StyledAvatar from './AvatarStyle';

const style = {
  objectFit: 'cover',
};

const noStyle = {};

export interface AvatarProps extends BorderProps, ColorProps {
  src?: string;
  size?: number;
  children?: any;
  [key: string]: any;
}

const Avatar: React.FC<AvatarProps> = ({ src, size, children, fit, ...props }) => {
  return (
    <StyledAvatar size={size} {...props}>
      {src && <img src={src} alt="avatar" style={fit ? style : noStyle} />}
      {!src && children && <span>{children}</span>}
    </StyledAvatar>
  );
};

Avatar.defaultProps = {
  size: 48,
};

export default Avatar;
